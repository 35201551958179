<template>
  <v-container class="pa-0">
    <v-row class="my-0" :dense="dense">
      <v-col cols="6">
        <NewsPostItem v-bind="$attrs" :type="itemType" :post="items[0]" :height="computedHeight" />
      </v-col>
      <v-col cols="6">
        <v-row :dense="dense">
          <v-col
            cols="12"
            v-for="(item, i) in items.slice(1, 3)"
            :key="`vertical` + i"
            v-bind="item.col"
          >
            <NewsPostItem
              v-bind="$attrs"
              :type="itemType"
              small
              :height="computedHeight / 2 - 4"
              :post="item"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-0" :dense="dense">
      <v-col
        cols="6"
        v-for="(item, i) in items.slice(3, 5)"
        :key="`horizontal` + i"
        v-bind="item.col"
      >
        <NewsPostItem :type="itemType" small :height="computedHeight / 2 - 4" :post="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsPostItem from "@/blocks/news/components/news-post-item/NewsPostItem";
export default {
  components: {
    NewsPostItem,
  },
  props: {
    height: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemType: {
      type: String,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedHeight() {
      if (this.height) return this.height;
      if (this.$vuetify.breakpoint.lgAndUp) return 450;
      return 380;
    },
  },
};
</script>
