<style lang="scss">
</style>

<template>
  <div>
    <div v-if="items.length > 4">
      <NewsGrid :items="items" item-type="Alfa"></NewsGrid>
    </div>
  </div>
</template>

<script>
import NewsGrid from "./news-grid/NewsGrid";
export default {
  components: {
    NewsGrid,
  },
  computed: {
    items() {
      return this.$store.getters["news/getBy"]("posts", "is_featured", true);
    },
  },
};
</script>
