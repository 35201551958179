<style lang="scss">
</style>

<template>
  <group-card>
    <template #title>
      <slot name="title">
        <router-link
          class="hover-underline"
          v-if="category.slug"
          :to="{ name: 'NewsCategory', params: { slug: category.slug } }"
          :title="`Sirvi kõiki uudiseid kategoorias &quot;${category.title}&quot;`"
        >
          {{ category.title }}
        </router-link>
        <div class="primary--text" v-else>Kõik uudised</div>
      </slot>
    </template>
    <NewsGrid
      :dense="false"
      narrow
      :items="items"
      item-type="Delta"
      v-bind="{ ...category.attrs, ...$attrs }"
    />
  </group-card>
</template>

<script>
import NewsGrid from "./news-grid/NewsGrid";

export default {
  components: {
    NewsGrid,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  computed: {
    posts() {
      if (!this.category.slug) return this.$store.state.news.posts;

      return this.$store.getters["news/getBy"]("posts", "category_slug", this.category.slug);
    },
    items() {
      return this.posts.slice(0, 5);
    },
  },
};
</script>
