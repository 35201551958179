<style lang="scss">
@import "./../youtube-player";
</style>


<template>
  <div class="ytube__container">
    <div class="ytube__player" :class="{ ytube__full: playlist.length === 1 }" ref="player">
      <VideoIframe v-if="active" :video="active" :width="width" :height="height" />
    </div>

    <Playlist
      :toolbar-height="toolbarHeight"
      ref="playlist"
      v-if="playlist.length > 1"
      :videos="playlist"
      @play="setActiveVideo"
      :playlist-height="playlistHeight"
      :playlist-width="playlistWidth"
    />
  </div>
</template>

<script>
import Playlist from "./Playlist";
import { parseVideo, debounce } from "./../youtube-player-helpers";
import VideoIframe from "./VideoIframe.vue";

const TOOLBAR_HEIGHT = 48;

export default {
  components: {
    VideoIframe,
    Playlist,
  },

  props: {
    videos: {
      type: Array,
      required: true,
    },

    btnColour: {
      type: String,
      default: "#fff",
    },

    aspectRatio: {
      type: String,
      default: "640:360",
    },
  },

  data() {
    return {
      playlist: [],
      width: 640,
      height: 360,
      windowWidth: window.innerWidth,
      toolbarHeight: TOOLBAR_HEIGHT,
    };
  },

  beforeMount() {
    this.videos.forEach((video) => {
      this.playlist.push(parseVideo(video));
    });

    [this.width, this.height] = this.aspectRatio.split(":");
  },

  mounted() {
    this.playlist[0].active = true;

    this.$nextTick(() => {
      window.onload = this.resizeVideo();
      window.onresize = debounce(() => this.resizeVideo(), 70);
    });
  },

  methods: {
    setActiveVideo(video) {
      this.active.active = false;
      video.active = true;
    },

    resizeVideo() {
      this.windowWidth = window.innerWidth;

      if (this.$refs.playlist && this.$refs.playlist.scrollbar) {
        this.$refs.playlist.scrollbar.update();
      }
      if (this.$refs.player) {
        const ratio = this.height / this.width;
        this.width = this.$refs.player.getBoundingClientRect().width;
        this.height = this.width * ratio;
      }
    },
  },

  computed: {
    active() {
      return this.playlist.find((video) => video.active);
    },

    playlistHeight() {
      if (this.windowWidth > 620) {
        return this.height - TOOLBAR_HEIGHT + "px";
      }

      return "220px";
    },

    playlistWidth() {
      if (this.windowWidth > 620) {
        return "100%";
      }

      return this.playlist.length * 320 + "px";
    },
  },
};
</script>
