<style lang="scss">
</style>

<template>
  <v-sheet>
    <v-toolbar dense :height="toolbarHeight" color="primary" dark>
      <v-icon left>mdi-play</v-icon>
      <v-toolbar-title>Kasulikud videod</v-toolbar-title>
    </v-toolbar>
    <div ref="scrollarea" class="ytube__playlist" :style="`height: ${playlistHeight};`">
      <div class="ytube__scrollable-content">
        <v-list two-line :color="'#111111'">
          <v-list-item
            style="border-top: 1px solid #474747"
            class="border-bottom link__wrap"
            :class="video.active ? 'white' : ''"
            v-for="(video, i) in videos"
            :key="`video` + i"
            @click="watch(video)"
          >
            <v-list-item-action class="mr-2">
              <v-img
                class="elevation-1"
                :width="100"
                :aspect-ratio="16 / 9"
                :src="video.thumbnail"
              ></v-img>
            </v-list-item-action>
            <v-list-item-content style="align-items: start !important">
              <v-list-item-subtitle class="text-wrap mt-1 text-body-2">
                <span :class="{ 'white--text link': !video.active }">{{ video.title }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
export default {
  mounted() {
    if (this.$refs.scrollarea) {
      this.scrollbar = new PerfectScrollbar(this.$refs.scrollarea);
      this.interval = setInterval(() => {
        if (this.scrollbar) this.scrollbar.update();
      }, 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  destroyed() {
    if (this.scrollbar) {
      this.scrollbar.destroy();
      this.scrollbar = null;
    }
  },
  data() {
    return {
      interval: null,
      scrollbar: null,
    };
  },
  props: {
    videos: { type: Array, required: true },
    playlistHeight: { type: String, required: true },
    playlistWidth: { type: String, required: true },
    toolbarHeight: { type: Number, required: true },
  },
  data() {
    return {
      thumbnailWidth: 100,
      active: 1,
    };
  },
  methods: {
    watch(video) {
      this.$emit("play", video);
    },
  },
};
</script>
