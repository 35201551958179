<template>
  <div class="post">
    <div class="d-flex justify-center">
      <div>
        <v-sheet>
          <div class="d-flex justify-space-between align-center flex-wrap mx-5 pt-5">
            <PostBreadcrumbs
              :current="post.title"
              :category="$store.getters['news/categoryOf'](post)"
              class="mr-3"
            />
            <span outlined small class="text--disabled">
              <v-icon x-small left>mdi-clock-outline</v-icon>
              <small>{{ post.date }}</small>
            </span>
          </div>
          <div class="px-5 pt-2 pb-1">
            <h1
              class="pb-1"
              :class="`${$vuetify.breakpoint.xs ? 'text-h4' : 'text-h2'}`"
              v-html="post.title"
            ></h1>
          </div>

          <div class="px-5 pb-3 d-flex align-center justify-space-between text-no-wrap flex-wrap">
            <div class="mr-3" style="min-height: 48px">
              <PostAuthor v-if="author" :author="author"></PostAuthor>
            </div>
            <ShareButtons :title="post.title" />
          </div>

          <PostImage :post="post"></PostImage>

          <div class="pa-5">
            <HtmlContent v-if="content" :content="content" />
            <div class="d-flex justify-center">
              <ShareButtons :title="post.title" />
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlContent from "@/blocks/global/components/HtmlContent";
import PostImage from "./PostImage";
import PostAuthor from "./PostAuthor";

import ShareButtons from "./ShareButtons";
import PostBreadcrumbs from "./PostBreadcrumbs";

export default {
  components: {
    PostBreadcrumbs,
    ShareButtons,
    PostAuthor,
    PostImage,
    HtmlContent,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  computed: {
    author() {
      return this.post.author;
    },
    content() {
      return this.post.content
        ? this.post.content.replace("<img ", "<img loading=lazy ")
        : this.post.content;
    },
    hasContentAndAuthor() {
      return this.post.content ? (this.post.author ? true : false) : false;
    },
  },
};
</script>
