
<template>
  <!--
    This is only post item that has image as background. Scales better for NewsGridTwoCols.
 -->
  <router-link
    class="link__wrap image-gradient__wrap"
    :to="{ name: 'NewsPost', params: { id: post.id, slug: post.slug } }"
    :title="post.title"
  >
    <v-sheet color="transparent" class="mx-auto py-auto">
      <div
        class="d-flex flex-column justify-end"
        :style="height ? `height:${height}px` : undefined"
      >
        <v-flex class="position-relative">
          <!-- <base-card class="h-100 w-100"> -->
          <div class="image-gradient"></div>
          <div
            class="h-100 w-100 elevation-1"
            :style="
              mounted
                ? `background: url('${post.image}') no-repeat center; background-size: cover;`
                : undefined
            "
          ></div>
          <!-- </base-card> -->
        </v-flex>
        <div class="py-2 d-flex align-top" style="min-height: 64px">
          <div style="line-height: 2 !important">
            <v-clamp
              autoresize
              v-if="clamp"
              :class="`link text-${small ? 'h6' : 'h4'}`"
              tag="h3"
              :max-lines="clampMaxLines"
            >
              {{ post.title }}
            </v-clamp>
            <h3 v-else :class="`link text-${small ? 'h6' : 'h4'}`">{{ post.title }}</h3>
          </div>
        </div>
      </div>
    </v-sheet>
  </router-link>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 500);
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: undefined,
    },
    clamp: {
      type: Boolean,
      default: true,
    },
    clampMaxLines: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
};
</script>
