<template>
  <div>
    <v-container class="pt-0 pb-10">
      <v-row>
        <v-col class="pb-0">
          <PopularNews />
          <FeaturedNews />
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col
          class="flex-grow-1 flex-shrink-0 pb-0"
          :style="$vuetify.breakpoint.mdAndUp ? `max-width: calc(100% - (300px + 2 * 12px))` : null"
        >
          <template v-for="(category, i) in [{ slug: null }, ...categories]">
            <CategoryNews
              :key="category.slug"
              :alternative="i % 2 !== 0"
              :category="category"
              class="mb-5"
            />
            <div :key="`ad-${i}`" class="d-flex justify-center">
              <Advertisement
                :show-only-index="i"
                v-show="showAd"
                :max-width="adParentWidth - 20"
                route="News"
                placement="BetweenCategories"
              />
            </div>
          </template>
          <div class="d-block" id="js-ad-parent"></div>

          <v-container class="pa-0" v-if="$vuetify.breakpoint.xl">
            <v-row class="my-0">
              <v-col class="pt-0 pb-5">
                <YoutubePlayer v-if="$store.state.afterMounted" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          style="max-width: calc(300px + 2 * 12px)"
          class="pb-0"
        >
          <MainSidebar />
        </v-col>

        <v-col cols="12" class="py-0">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.lgAndDown">
            <v-row class="my-0">
              <v-col class="pt-0 pb-5">
                <YoutubePlayer v-if="$store.state.afterMounted" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="12" class="pt-0" v-if="$store.state.afterMounted">
          <AllNews />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MainSidebar from "@/blocks/global/components/MainSidebar";
import Advertisement from "@/blocks/advertisement/components/Advertisement";
import YoutubePlayer from "@/blocks/global/youtube-player/YoutubePlayer";
import LatestBusinesses from "@/blocks/business/components/LatestBusinesses";
import AllNews from "@/blocks/news/components/AllNews";
import CategoryNews from "@/blocks/news/components/CategoryNews";
import FeaturedNews from "@/blocks/news/components/FeaturedNews";
import PopularNews from "@/blocks/news/components/PopularNews";
import adParentMixin from "@/mixins/adParentMixin.js";
export default {
  mixins: [adParentMixin],
  components: {
    PopularNews,
    FeaturedNews,
    CategoryNews,
    AllNews,
    LatestBusinesses,
    YoutubePlayer,
    Advertisement,
    MainSidebar,
  },

  head() {
    return this.$store.getters.content("news.head");
  },

  computed: {
    categories() {
      return this.$store.state.news.categories;
    },
  },
};
</script>
