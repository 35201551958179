<template>
  <div>
    <div class="d-flex align-center">
      <span class="text-h6"><slot>Jaga uudist</slot></span>
      <ShareNetwork
        v-for="network in networks"
        :key="network"
        :network="network"
        v-bind="properties"
      >
        <v-btn v-bind="$attrs" class="ml-1" icon :title="`Jaga lehte ${network}i teel`">
          <v-icon>mdi-{{ network }}</v-icon>
        </v-btn>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
const networks = ["facebook", "twitter", "linkedin", "email"];
export default {
  props: {
    networks: {
      type: Array,
      default: () => networks,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
  },
  computed: {
    properties() {
      return {
        url: this.url ? this.url : window.location.href,
        title: this.title,
        description: this.description ? this.description : "Ehitussektor - Uus veebiportaal",
        hashtags: "ehitussektor",
      };
    },
  },
};
</script>

<style>
</style>
