<style lang="scss">
</style>

<template>
  <div class="d-flex align-center" title="Artikli autor">
    <v-avatar class="mr-2" color="grey lighten-4">
      <v-img v-if="author.avatar" :src="author.avatar"></v-img>
      <v-icon v-else>mdi-lead-pencil</v-icon>
    </v-avatar>
    <div style="line-height: 1">
      <div>
        <small class="mr-3 font-weight-bold">{{ author.name }}</small>
      </div>
      <div>
        <small class="mr-3 text--disabled">
          <a class="link text--disabled" :href="`mailto:${author.email}`">
            {{ author.email }}
          </a>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
};
</script>
