<style lang="scss">
</style>

<template>
  <div>
    <div v-if="!narrow">
      <NewsGridThreeCols :items="items" v-bind="$attrs" v-if="$vuetify.breakpoint.lgAndUp" />
      <NewsGridTwoCols :items="items" v-bind="$attrs" v-else-if="$vuetify.breakpoint.smAndUp" />
      <NewsGridOneCol :items="items" v-bind="$attrs" v-else />
    </div>
    <div v-else>
      <NewsGridThreeCols
        item-type="Delta"
        :clamp-max-lines="3"
        :aspect-ratio="5 / 4"
        :items="items"
        v-bind="$attrs"
        v-if="alternative && $vuetify.breakpoint.lgAndUp"
      />
      <NewsGridTwoCols
        :items="items"
        item-type="Bravo"
        :clamp="false"
        v-bind="$attrs"
        v-else-if="alternative && $vuetify.breakpoint.smAndUp"
      />
      <NewsGridTwoRowsPyramid
        :items="items"
        v-bind="$attrs"
        :clamp="false"
        v-else-if="$vuetify.breakpoint.smAndUp"
      />
      <NewsGridOneCol :items="items" v-bind="$attrs" v-else />
    </div>
  </div>
</template>

<script>
import NewsGridOneCol from "./NewsGridOneCol";
import NewsGridTwoCols from "./NewsGridTwoCols";
import NewsGridTwoRowsPyramid from "./NewsGridTwoRowsPyramid";
import NewsGridThreeCols from "./NewsGridThreeCols";
export default {
  components: {
    NewsGridThreeCols,
    NewsGridTwoCols,
    NewsGridTwoRowsPyramid,
    NewsGridOneCol,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "Alfa",
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    alternative: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
