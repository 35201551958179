<template>
  <div>
    <Player :videos="videos" v-if="fetched && videos.length"></Player>
  </div>
</template>

<script>
import Player from "./components/Player.vue";
import injectables from "@/common/injectables";
export default {
  components: {
    Player,
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      fetched: null,
      videos: [],
    };
  },

  methods: {
    async fetch() {
      if (this.fetched) return;
      this.fetched = false;

      const injectedVideos = this._.get(injectables, "player_videos");
      if (Array.isArray(injectedVideos)) {
        this.videos = injectedVideos;
        this.fetched = true;
        return;
      }

      const response = await this.axios.get("player-videos");
      this.videos = response.data;
      this.fetched = true;
    },
  },
};
</script>
