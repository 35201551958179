
<template>
  <router-link
    class="link__wrap image-gradient__wrap"
    :to="{ name: 'NewsPost', params: { id: post.id, slug: post.slug } }"
    :title="post.title"
  >
    <div class="d-flex flex-column justify-end">
      <v-img :src="post.image" :aspect-ratio="aspectRatio" class="image-gradient__wrap elevation-1">
        <div class="image-gradient" />
        <router-link
          :to="{ name: 'NewsPost', params: { id: post.id, slug: post.slug } }"
          class="bottom-gradient__content d-flex align-end white--text pa-3"
          :title="post.title"
        >
          <!-- <div>
            <v-chip small label color="black" dark class="pa-2 mb-2">
              {{ $store.getters['news/categoryOf'](post).title }}
            </v-chip>
          </div> -->
        </router-link>
      </v-img>

      <div class="pt-2 d-flex align-top" style="min-height: 64px">
        <div>
          <div style="line-height: 2 !important">
            <v-clamp
              autoresize
              v-if="clamp"
              :class="`link text-${small ? 'h6' : 'h5'}`"
              tag="h3"
              :max-lines="clampMaxLines"
            >
              {{ post.title }}
            </v-clamp>
            <h3 v-else :class="`link text-${small ? 'h6' : 'h5'}`">{{ post.title }}</h3>
          </div>
          <div v-if="showDate" class="text--disabled">
            <v-icon x-small left>mdi-clock-outline</v-icon>
            <small>{{ post.date }}</small>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  mounted() {
    this.mounted = true;
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    clamp: {
      type: Boolean,
      default: true,
    },
    clampMaxLines: {
      type: Number,
      default: 2,
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
};
</script>
