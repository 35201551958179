export default {
  data() {
    return {
      mixinCacheKey: this.$route.fullPath,
      mixinCacheFields: [],
    };
  },

  /* SIMPLE USAGE EXAMPLE:
        * Define cacheable fields, cache key and pull the existing cache when component is created:
        created() {
            this.pullCache(["posts", "query"],"AllNews" + this.$route.path);
        },
        * Save the cache when component is destroyied
        destroyed() {
            this.saveCache();
        },
  */
  methods: {
    /**
     * Store the current state of the component in the vuex store
     * @param {*} cacheFields
     */
    saveCache(cacheFields = null) {
      if (Array.isArray(cacheFields)) this.mixinCacheFields = cacheFields;

      const cacheableData = {};
      for (const field of this.mixinCacheFields) {
        cacheableData[field] = this[field];
      }
      this.$store.commit("SET_CACHE", {
        [this.mixinCacheKey]: cacheableData,
      });
    },

    /**
     * Get the cached state for the current component from the vuex store
     * and unset the cache
     *
     * @param {*} cacheFields
     * @returns
     */
    pullCache(cacheFields = null, cacheKey = null) {
      if (Array.isArray(cacheFields)) this.mixinCacheFields = cacheFields;
      if (typeof cacheKey === "string") this.mixinCacheKey = cacheKey;

      const cache = this.$store.state.cache[this.mixinCacheKey];

      if (!cache) return;

      for (const field of this.mixinCacheFields) {
        if (cache[field]) this[field] = cache[field];
      }

      this.$store.commit("UNSET_CACHE", this.mixinCacheKey);
    },
  },
};
