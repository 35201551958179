<template>
  <div class="my-5">
    <div v-if="item" :class="`d-${bp}-flex align-center`">
      <v-chip style="min-width: 140px" color="primary" label dark v-if="!mobile">
        <strong>Hetkel populaarne</strong>
      </v-chip>
      <div class="mx-2 d-flex justify-center d-sm-initial align-center">
        <v-btn class="mx-2" icon text @click="back()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-chip color="primary" label dark v-if="mobile">
          <strong>Hetkel populaarne</strong>
        </v-chip>
        <v-btn class="mx-2" icon text @click="forward()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <div :class="`text-center text-${bp}-left mt-3 mt-${bp}-0`">
        <v-fade-transition hide-on-leave>
          <router-link
            :key="index"
            :to="{ name: 'NewsPost', params: { id: item.id, slug: item.slug } }"
            class="link font-weight-medium"
          >
            <v-clamp autoresize :max-lines="$vuetify.breakpoint.mdAndUp ? 1 : 2">
              {{ item.title }}
            </v-clamp>
          </router-link>
        </v-fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      bp: "md",
    };
  },
  computed: {
    items() {
      return this.$store.getters["news/getBy"]("posts", "is_popular", true);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    item() {
      return this.items[this.index];
    },
  },
  methods: {
    back() {
      if (this.index === 0) return (this.index = this.items.length - 1);

      this.index--;
    },
    forward() {
      if (this.index === this.items.length - 1) return (this.index = 0);

      this.index++;
    },
  },
};
</script>
