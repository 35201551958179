<style lang="scss">
</style>

<template>
  <div>
    <BravoNewsPostItem v-if="type == 'Bravo'" v-bind="$attrs" />
    <CharlieNewsPostItem v-else-if="type == 'Charlie'" v-bind="$attrs" />
    <DeltaNewsPostItem v-else-if="type == 'Delta'" v-bind="$attrs" />
    <AlfaNewsPostItem v-else v-bind="$attrs" />
  </div>
</template>

<script>
import AlfaNewsPostItem from "./AlfaNewsPostItem";
import BravoNewsPostItem from "./BravoNewsPostItem";
import CharlieNewsPostItem from "./CharlieNewsPostItem";
import DeltaNewsPostItem from "./DeltaNewsPostItem";
export default {
  components: {
    AlfaNewsPostItem,
    BravoNewsPostItem,
    CharlieNewsPostItem,
    DeltaNewsPostItem,
  },
  props: {
    type: {
      type: String,
      default: "Alfa",
    },
  },
};
</script>
