<template>
  <v-container class="pa-0">
    <v-row class="my-0" :dense="dense">
      <v-col cols="6" v-for="(item, i) in items.slice(0, 2)" :key="`top` + i">
        <NewsPostItem v-bind="$attrs" :type="itemType" :post="item" />
      </v-col>
      <v-col cols="4" v-for="(item, i) in items.slice(2, 5)" :key="`bottom` + i">
        <NewsPostItem v-bind="$attrs" :type="itemType" :post="item" small />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsPostItem from "@/blocks/news/components/news-post-item/NewsPostItem";
export default {
  components: {
    NewsPostItem,
  },
  props: {
    height: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemType: {
      type: String,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedHeight() {
      if (this.height) return this.height;
      if (this.$vuetify.breakpoint.lgAndUp) return 450;
      return 380;
    },
  },
};
</script>
