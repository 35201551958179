<template>
  <v-container class="pa-0">
    <v-row class="my-0" :dense="dense">
      <v-col cols="12" v-for="(item, i) in items" :key="`vertical` + i" v-bind="item.col">
        <NewsPostItem
          v-bind="$attrs"
          :type="itemType"
          small
          :height="250"
          :clamp="itemType === 'Alfa' ? clamp : false"
          :post="item"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import NewsPostItem from "@/blocks/news/components/news-post-item/NewsPostItem";
export default {
  components: {
    NewsPostItem,
    NewsPostItem,
  },
  props: {
    height: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemType: {
      type: String,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clamp: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    computedHeight() {
      if (this.height) return this.height;
      if (this.$vuetify.breakpoint.lgAndUp) return 450;
      return 380;
    },
  },
};
</script>
