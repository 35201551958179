<style lang="scss">
.page.post {
  .group-card {
    .v-list {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
</style>
<template>
  <div class="category page">
    <v-container class="pt-0 pb-10" v-if="category" :key="category.slug">
      <v-row class="mt-5">
        <v-col class="flex-grow-1 flex-shrink-0 pb-0">
          <v-sheet color="white">
            <CategoryNews show-date :category="category" class="mb-0">
              <template #title>
                <div class="mb-1" v-html="category.long_title"></div>
              </template>
            </CategoryNews>
            <AllNews
              :excluded-ids="excludedIds"
              :show-category="false"
              :category="category"
              :col-sizes="{ lg: 3 }"
              hide-title
            />
          </v-sheet>

          <div class="d-block" id="js-ad-parent"></div>
          <div class="d-flex align-center mt-5">
            <Advertisement
              v-show="showAd"
              :max-width="adParentWidth - 20"
              route="NewsCategory"
              placement="AfterNews"
            />
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" style="max-width: calc(300px + 2 * 12px)">
          <MainSidebar />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Advertisement from "@/blocks/advertisement/components/Advertisement";
import MainSidebar from "@/blocks/global/components/MainSidebar";
import AllNews from "../components/AllNews";
import CategoryNews from "../components/CategoryNews";
import SameCategoryPosts from "../components/SameCategoryPosts";
import PostCategories from "../components/PostCategories";
import PostContent from "../components/PostContent";
import adParentMixin from "@/mixins/adParentMixin.js";
export default {
  mixins: [adParentMixin],
  components: {
    PostContent,
    SameCategoryPosts,
    PostCategories,
    CategoryNews,
    AllNews,
    MainSidebar,
    Advertisement,
  },

  head() {
    return this.category.head;
  },

  computed: {
    category() {
      return this.$store.state.news.categories.find(
        (category) => category.slug === this.$route.params.slug,
      );
    },
    news() {
      return this.$store.getters["news/getBy"]("posts", "category_slug", this.category.slug).slice(
        0,
        5,
      );
    },
    excludedIds() {
      return this.news.map((el) => {
        return el.id;
      });
    },
  },
};
</script>
