<style lang="scss">
</style>

<template>
  <group-card title="Uudiste kategooriad">
    <v-list dense>
      <v-list-item
        :title="`${category.title} kategooria | (${category.posts_count} uudist)`"
        color="primary"
        :to="{ name: `NewsCategory`, params: { slug: category.slug } }"
        exact
        v-for="category in categories"
        :key="category.slug"
      >
        <v-list-item-content>
          <v-list-item-title>{{ category.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <small class="disabled--text">({{ category.posts_count }})</small>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </group-card>
</template>

<script>
export default {
  computed: {
    news() {
      return this.$store.state.news.posts;
    },
    categories() {
      return this.$store.state.news.categories;
    },
  },
};
</script>
