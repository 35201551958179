import { getWidth } from "@/common/viewport.js";
export default {
  data() {
    return {
      adParentWidth: undefined,
      adWidth: undefined,
      disableAd: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      setTimeout(() => {
        this.onResize();
      }, 100);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    showAd() {
      return this.adParentWidth && !this.disableAd;
    },
  },

  methods: {
    onResize() {
      this.disableAd = true;
      this.setAdWidth();
    },

    setAdWidth: _.debounce(function () {
      const newWidth = getWidth("#js-ad-parent");
      this.adParentWidth = newWidth;
      this.disableAd = false;
    }, 500),
  },
};
