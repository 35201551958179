<style lang="scss">
.same-category-posts {
}
</style>

<template>
  <group-card class="same-category-posts" title="Samas kategoorias">
    <v-list class="mb-2" two-line>
      <v-list-item
        class="py-0 border-bottom link__wrap"
        v-for="(post, i) in items"
        :key="`post` + i"
        excact
        :to="{ name: 'NewsPost', params: { id: post.id, slug: post.slug } }"
      >
        <v-list-item-action class="mr-2">
          <v-img
            class="elevation-1"
            :width="70"
            :aspect-ratio="16 / 9"
            :src="post.small_thumbnail"
          ></v-img>
        </v-list-item-action>
        <v-list-item-content style="align-items: start !important">
          <v-list-item-subtitle class="text-wrap mt-1 text-body-2">
            <span :class="{ link: !post.active }">{{ post.title }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </group-card>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      return this.$store.state.news.posts
        .filter(
          (item) => item.category_slug === this.post.category_slug && item.id !== this.post.id,
        )
        .slice(0, 3);
    },
  },
};
</script>
