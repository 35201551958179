<style lang="scss">
</style>

<template>
  <div>
    <v-sheet elevation="1">
      <v-img :alt="post.image_title" :src="post.image" aspect-ratio="1" max-height="300px"></v-img>
    </v-sheet>
    <div class="mx-5 d-sm-flex justify-space-between">
      <div>
        <small>{{ post.image_title }}</small>
      </div>
      <div v-if="post.image_author">
        <small class="text-uppercase text--disabled">Foto: {{ post.image_author }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>
