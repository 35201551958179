<style lang="scss">
</style>

<template>
  <div class="d-flex">
    <v-breadcrumbs class="pa-0" :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      required: true,
    },
    category: {
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          text: "Uudised",
          to: { name: "Home" },
        },
        {
          text: this.category.title,
          disabled: true,
          link: true,
        },
        // {
        //   text: this.current,
        //   disabled: true,
        // },
      ],
    };
  },
};
</script>
