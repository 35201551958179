<style lang="scss">
.page.post {
  .group-card {
    .v-list {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
</style>
<template>
  <div class="post page">
    <NotFound v-if="notFound"></NotFound>
    <v-container v-else-if="post" class="pt-0 pb-10">
      <v-row class="mt-5">
        <v-col v-if="$vuetify.breakpoint.lgAndUp" style="max-width: calc(300px + 2 * 12px)">
          <SameCategoryPosts class="mb-5" :post="post" />
          <PostCategories :active-category="$store.getters['news/categoryOf'](post)" />
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 pb-0">
          <PostContent :post="post" />
          <div class="d-block" id="js-ad-parent"></div>
          <div class="d-flex justify-center mt-5">
            <Advertisement
              v-show="showAd"
              :max-width="adParentWidth - 20"
              route="NewsPost"
              placement="AfterContent"
            />
          </div>
          <div v-if="$vuetify.breakpoint.smAndDown">
            <SameCategoryPosts :post="post" />
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" style="max-width: calc(300px + 2 * 12px)">
          <MainSidebar />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import MainSidebar from "@/blocks/global/components/MainSidebar";
import Advertisement from "@/blocks/advertisement/components/Advertisement";
import NotFound from "@/blocks/not-found/views/NotFound";
import SameCategoryPosts from "../components/SameCategoryPosts";
import PostCategories from "../components/PostCategories";
import PostContent from "../components/PostContent";
import adParentMixin from "@/mixins/adParentMixin.js";
export default {
  mixins: [adParentMixin],
  components: {
    PostContent,
    SameCategoryPosts,
    PostCategories,
    NotFound,
    Advertisement,
    MainSidebar,
  },

  head() {
    return this.loading ? {} : this.post.head;
  },

  created() {
    this.setLocalClonedPost();
    this.fetch();
  },

  data() {
    return {
      loading: null,
      notFound: null,
      post: null,
    };
  },

  watch: {
    $route() {
      this.forceFetch();
    },
  },

  computed: {
    /*
     * Determines if we already have full data of post or only basic data
     * that were used to index posts.
     */
    isFinal() {
      return this.post && this.post.final;
    },
  },

  methods: {
    /**
     * * Clone local copy of the post.
     *
     * At first, there could only be post's basic information and
     * author, content etc will be fetched on demand, so we need
     * to clone new object to make sure everything re-renders
     * after full data of post is fetched.
     */
    setLocalClonedPost() {
      this.post = this._.cloneDeep(
        this.$store.getters["news/findBy"]("posts", "id", parseInt(this.$route.params.id)),
      );
    },

    async fetch() {
      if (this.isFinal) {
        this.fixRouteSlugParameter();
        return;
      }
      this.forceFetch();
    },

    async forceFetch() {
      this.loading = true;
      try {
        const response = await this.axios.get("post/" + this.$route.params.id);
        this.$store.commit("news/ADD_OR_UPDATE_POST", response.data);

        this.setLocalClonedPost();
        this.fixRouteSlugParameter();
      } catch (error) {
        if (error.response.status === 404) this.notFound = true;
      } finally {
        this.loading = false;
      }
    },

    fixRouteSlugParameter() {
      if (this.$route.params.slug) return;

      this.$router.replace({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          slug: this.post.slug,
        },
      });
    },
  },
};
</script>
